import React from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .cls-1{
    fill:#1d1d1b;
  }
`

const GridIcon = () => {
  return (
    <StyledSvg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 63 63"
      width="16"
      height="16"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect class="cls-1" width="27" height="27"/>
          <rect class="cls-1" x="36" y="36" width="27" height="27"/>
          <rect class="cls-1" y="36" width="27" height="27"/>
          <rect class="cls-1" x="36" width="27" height="27"/>
        </g>
      </g>
    </StyledSvg>
  )
}

export default GridIcon
import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import Slider from "react-slick"
import variables from "../styles/variables"
import GridIcon from "../components/Core/Icons/Grid"
import CarouselIcon from "../components/Core/Icons/Carousel"

const PageHeader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 16px;

  @media (min-width: ${breakpoints.md}) {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }
`

const ToggleButton = styled.button`
  height: 16px;
  width: 16px;
  margin-right: 36px;
  padding-top: 4px;
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`

const Title = styled.h2`

`

const CurrentlyViewing = styled.h3`
  margin-right: 48px;
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`

const Description = styled.div`
  width: 100%;
  padding-top: 16px;
  font-family: "ITC Century W01 Book", serif;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }

  p {
    padding-bottom: 1em;
  }

  i {
    font-style: italic;
  }

  a {
    text-underline-position: under;

    :hover {
      text-decoration: underline;
    }
  }
`

const Caption = styled.div`
  margin-left: 24px;
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(1em - 8px);
  margin-right: calc(1em - 8px);
  padding-bottom: 108px;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    margin-left: auto;
    margin-right: auto;
  }
`

const SliderContainer = styled.div`
  width: calc(100vw - 2em + 16px);
  margin: auto;

  @media (min-width: ${breakpoints.md}) {
    width: 87vw;
  }

  @media (min-width: ${breakpoints.lgw}) {
    width: 88vw;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 90vw;
  }

  @media (min-width: ${breakpoints.xxl}) {
    width: 92vw;
  }
`

const StyledSlider = styled(Slider)`
  .slick-prev, .slick-next {
    :before {
      content: "" !important;
    }
  }

  .slick-prev:hover {
    transform: rotateZ(225deg) translateX(12px) translateY(-12px) !important;
  }

  .slick-next:hover {
    transform: rotateZ(45deg) translateX(12px) translateY(-12px) !important;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
`

const WorkCard = styled.div`
  padding-left: 2.25em;
  padding-right: 2.25em;
  padding-top: 12px;
  padding-bottom: 60px;
  width: 100%;
  ${(props) => (props.embed ? "width: 100% !important" : "")};

  #embed {
    width: 100%;

    iframe {
      width: 100%;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 50%;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: calc(100% / 3);
  }

/* 
  @media (min-width: ${breakpoints.xxl}) {
    width: 25%;
  }

  @media (min-width: ${breakpoints.xxxl}) {
    width: 20%;
  } */
`

const StyledGatsbyImage = styled(GatsbyImage)`
  height: calc((100vw - 4.5em) * 0.75);

  @media (min-width: ${breakpoints.lg}) {
    height: calc((50vw - 4.5em) * 0.75);
  }

  @media (min-width: ${breakpoints.xl}) {
    height: calc(((100vw / 3) - 4.5em) * 0.75);
  }
/* 
  @media (min-width: ${breakpoints.xxl}) {
    height: calc((25vw - 4.5em) * 0.75);
  }

  @media (min-width: ${breakpoints.xxxl}) {
    height: calc((20vw - 4.5em) * 0.75);
  } */
`

const CarouselCard = styled.div`
  width: 100%;
  display: flex !important;
  padding-top: 12px;
  padding-bottom: 60px;

  #embed {
    width: 100%;
    display: flex;
    align-items: center;
    iframe {
      width: 100%;
    }
  }

  &:first-child {
    padding-bottom: 0;
  }

  .gatsby-image-wrapper {
    margin: unset;
    margin-left: 8px;
    margin-right: 8px;
  }

  @media (min-width: ${breakpoints.md}) {
    padding-left: 44px;
    padding-right: 44px;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: center;
    height: calc(100vh - (8em + 54px));

    .gatsby-image-wrapper {
      img {
        max-height: calc(100vh - 14em) !important;
        object-fit: contain !important;
      }
    }
  }

  @media (min-width: ${breakpoints.lgw}) {
    padding-left: 96px;
    padding-right: 96px;
  }

  @media (min-width: ${breakpoints.xl}) {
    padding-left: 108px;
    padding-right: 108px;
  }

  @media (min-width: ${breakpoints.xxl}) {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media (min-width: 1440px) {
    .gatsby-image-wrapper {
      margin-left: ${(props) => (props.double ? "8px" : "auto")};
      margin-right: ${(props) => (props.double ? "8px" : "auto")};
    }
  }

  @media (min-width: 480px) and (max-width: ${breakpoints.xl}) and (min-height: 800px) {
    flex-direction: column;
    .gatsby-image-wrapper {
      :first-of-type {
        margin-bottom: ${(props) => (props.double ? "16px" : 0)};
      }
    }
  }
`

const TextBlock = styled.div`
  font-family: "ITC Century W01 Book", serif;
  display: none;
  padding-top: 2em;
  max-width: 600px;
  height: max-content;
  margin: auto;
  
  @media (min-width: ${breakpoints.md}) {
    display: inline-block;
  }

  p {
    padding-bottom: 1em;
  }

  i {
    font-style: italic;
  }

  a {
    text-underline-position: under;

    :hover {
      text-decoration: underline;
    }
  }
`

const prevStyle = {
  borderTop: `2px solid ${variables.colorBlackFG}`,
  borderRight: `2px solid ${variables.colorBlackFG}`,
  width: `32px`,
  height: `32px`,
  transform: `rotateZ(225deg)`,
  transitionTimingFunction: "ease-out",
	transitionDuration: ".5s",
}

const nextStyle = {
  borderTop: `2px solid ${variables.colorBlackFG}`,
  borderRight: `2px solid ${variables.colorBlackFG}`,
  width: `32px`,
  height: `32px`,
  transform: `rotateZ(45deg)`,
  transitionTimingFunction: "ease-out",
	transitionDuration: ".5s",
}

const WorkTemplate = ({ location, data }) => {
  const { page } = data
  const { title, description, media } = page

  const text = media.find(mediaEntry => mediaEntry.content)?.content

  const path = location.pathname

  const [carousel, setCarousel] = useState(true)
  const [slideIndex, setSlideIndex] = useState(0)
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlideIndex(0)
      }
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const parsedRawDescription = JSON.parse(description.raw)
  let metaDescription = parsedRawDescription.content[0]?.content[0]?.value

  if (!metaDescription) {
    metaDescription = title + " - Greg White photographer. Represented by Picture Club. Award winning for his commercial and personal projects, specialising in landscape, technology and automotive photography."
  } else if (metaDescription.length > 160) {
    metaDescription = metaDescription.slice(0, 159) + "..."
  }

  const handleToggleClick = () => {
    setSlideIndex(0)
    setCarousel(!carousel)
  }

  const NextArrow = (props) => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, ...nextStyle }}
        onClick={onClick}
      />
    )
  }

  const PrevArrow = (props) => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, ...prevStyle }}
        onClick={onClick}
      />
    )
  }

  const getCarousel = () => {

    const settings = {
      dots: false,
      draggable: false,
      centerMode: false,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      beforeChange: (current, next) => setSlideIndex(next),
      responsive: [{
        breakpoint: breakpoints.md,
        settings: "unslick"
      }]
    }

    return (
      <SliderContainer>
        <StyledSlider {...settings}>
          {media && media.map(({ mainImage, additionalImage, thirdImage, embedCode, content, caption }, index) => {
            return (
              <CarouselCard key={index} double={additionalImage}>
                {content && <TextBlock>{renderRichText(content)}</TextBlock>}
                {mainImage && <GatsbyImage alt={caption || title} image={mainImage.gatsbyImageData} />}
                {additionalImage && <GatsbyImage alt={caption || title} image={additionalImage.gatsbyImageData} />}
                {thirdImage && <GatsbyImage alt={caption || title} image={thirdImage.gatsbyImageData} />}
                {embedCode && <div id="embed" dangerouslySetInnerHTML={{__html: embedCode.embedCode}}></div>}
              </CarouselCard>
            )
          })}
        </StyledSlider>
      </SliderContainer>
    )
  }

  const getGrid = () => {
    const gridItems = []

    {media && media.map(({ mainImage, additionalImage, thirdImage, embedCode }, index) => {
      mainImage && gridItems.push(mainImage)
      additionalImage && gridItems.push(additionalImage)
      thirdImage && gridItems.push(thirdImage)
      embedCode && gridItems.push(embedCode)
    })}

    return (
      <>
        {gridItems.map((gridItem, index) => {
          return (
            <WorkCard key={index} embed={gridItem.embedCode}>
              {gridItem.gatsbyImageData &&
                <StyledGatsbyImage
                  alt={title}
                  image={gridItem.gatsbyImageData}
                  objectFit="contain"
                  objectPosition="left"
                />
              }
              {gridItem.embedCode && <div id="embed" dangerouslySetInnerHTML={{__html: gridItem.embedCode}}></div>}
            </WorkCard>
          )
        })}
      </>
    )
  }

  return (
    <Layout>
      <Seo
        title={title}
        description={metaDescription}
        image={media[0]?.mainImage?.url}
        path={path}
      />
      <>
        <PageHeader>
          <ToggleButton onClick={() => handleToggleClick()}>
            {carousel ? <GridIcon /> : <CarouselIcon />}
          </ToggleButton>
          {carousel && (
            <CurrentlyViewing>
              ({slideIndex + 1} of {media.length})
            </CurrentlyViewing>
          )}
          <Title>{title}</Title>
          {media[slideIndex].caption ? (
            <Caption>{media[slideIndex].caption}</Caption>
          ) : null}
          {text && <Description>{renderRichText(text)}</Description>}
        </PageHeader>
        <Container>{carousel ? getCarousel() : getGrid()}</Container>
      </>
    </Layout>
  )
}

export default WorkTemplate

export const workTemplateQuery = graphql`
  query($slug: String!) {
    page: contentfulWork(slug: { eq: $slug }) {
      id
      title
      description {
        raw
      }
      media {
        ... on ContentfulMedia {
          id
          thirdImage {
            gatsbyImageData(width: 1000)
          }
          mainImage {
            gatsbyImageData(width: 1000)
            url
          }
          additionalImage {
            gatsbyImageData(width: 1000)
          }
          caption
        }
        ... on ContentfulVimeoEmbed {
          id
          embedCode {
            embedCode
          }
          caption
        }
        ... on ContentfulFreeText {
          id
          content {
            raw
          }
        }
      }
    }
  }
`

import React from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  .cls-1{
    fill:none;
    stroke:#1d1d1b;
    stroke-miterlimit:10;
    stroke-width:5px;
  }
  .cls-2{
    fill:#1d1d1b;
  }
`

const CarouselIcon = () => {
  return (
    <StyledSvg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 68 68"
      width="16"
      height="16"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect class="cls-1" x="2.5" y="2.5" width="63" height="63"/>
          <rect class="cls-2" x="2.5" y="47.5" width="63" height="18"/>
        </g>
      </g>
    </StyledSvg>
  )
}

export default CarouselIcon